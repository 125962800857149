.community h1 {
    font-family: 'Poppins', sans-serif;
    color: #000;
    font-size: 100px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: normal;
    text-transform: uppercase;
    margin-bottom: 40px;
    text-align: center;
}

.community h1 span {
    background: linear-gradient(to right, #5101e3, #8300e7, #a900eb, #cc00ee, #eb00f0);
    background-clip: text;
    color: transparent;
}

.community_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10;
    width: 100%;
    padding: 20px;
}

.community_img {
    border-radius: 10px;
    width: 100%;
}

@media screen and (min-width:800px) {
    .community_container {
        display: flex;
        flex-direction: row-reverse;
        font-size: 20px;
        gap: 5%;
    }
    .community_text {
        width: 50%;
        margin-right: 5%;
    }
    .community_img{
        width: 50%;
        margin-left: 5%;
    }
}

/* Extra Small Devices (Mobile Phones) */
@media (max-width: 575.98px) {
    .community h1 {
        font-size: 60px;
        margin-bottom: 20px;
    }

}

/* Small Devices (Tablets) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .community h1 {
        font-size: 80px;
        margin-bottom: 30px;
    }


}

/* Medium Devices (Laptops and Small Desktops) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .community h1 {
        font-size: 90px;
        margin-bottom: 35px;
    }


}