.Footer {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../images/footer-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.Footer h1 {
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-size: 100px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: normal;
  text-transform: uppercase;
  margin-bottom: 40px;
  text-align: center;
}

.Footer h1 span {
  background: linear-gradient(to right, #5101e3, #8300e7, #a900eb, #cc00ee, #eb00f0);
  background-clip: text;
  color: transparent;
}

.Footer .subtitle {
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 60px;
  text-align: center;
}

.Footer .cta {
  text-align: center;
  margin-bottom: 100px;
  border: none;
}

.Footer .cta a {
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 20px 60px;
  background: linear-gradient(25deg, #5101e3, #8300e7, #a900eb, #cc00ee, #eb00f0);
  color: #fff;
  border-radius: 50px;
  text-decoration: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.Footer .cta a:hover {
  background: linear-gradient(50deg, #5101e3, #8300e7, #a900eb, #cc00ee, #eb00f0);
}

.Footer .social-links {
  margin-top: 10px;
  padding-bottom: 20px;
}

.Footer .social-link {
  font-size: 20px;
}

@media screen and (min-width:800px) {
  .Footer .social-links {
    margin-top: 50px;
  }

  .Footer .social-link {
    font-size: 36px;
  }

}

.Footer .social-links,
.Footer .footer-links {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.Footer .social-links li,
.Footer .footer-links li {
  list-style: none;
  margin-right: 20px;
}

.Footer .social-links a,
.Footer .footer-links a {
  color: #666;
  text-decoration: none;
}

.Footer .social-links a {
  font-size: 20px;
}

.Footer .footer-links a {
  font-size: 18px;
}

.Footer .social-links a:hover,
.Footer .footer-links a:hover {
  color: #000;
}

.Footer .copy {
  font-family: 'Poppins', sans-serif;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  text-transform: none;
  text-align: center;
}

.Footer .credit {
  font-family: 'Poppins', sans-serif;
  color: #161616;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  text-transform: none;
  text-align: center;
}

.Footer .credit a {
  color: #5101e3;
  text-decoration: none;
}

/* Extra Small Devices (Mobile Phones) */
@media (max-width: 575.98px) {
  .Footer h1 {
    font-size: 60px;
    margin-bottom: 20px;
  }

  .Footer .subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .Footer .cta a {
    font-size: 16px;
    padding: 15px 40px;
  }

  .Footer .social-links a {
    font-size: 20px;
  }

  .Footer .footer-links a {
    font-size: 10px;
  }

  .Footer .copy {
    font-size: 12px;
  }

  .Footer .credit {
    font-size: 10px;
  }
}

/* Small Devices (Tablets) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .Footer h1 {
    font-size: 80px;
    margin-bottom: 30px;
  }

  .Footer .subtitle {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .Footer .social-links a {
    font-size: 16px;
  }

  .Footer .footer-links a {
    font-size: 14px;
  }

  .Footer .copy {
    font-size: 12px;
  }

  .Footer .credit {
    font-size: 10px;
  }
}

/* Medium Devices (Laptops and Small Desktops) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .Footer h1 {
    font-size: 90px;
    margin-bottom: 35px;
  }

  .Footer .subtitle {
    font-size: 20px;
    margin-bottom: 50px;
  }

  .Footer .social-links a {
    font-size: 18px;
  }

  .Footer .footer-links a {
    font-size: 16px;
  }

  .Footer .copy {
    font-size: 14px;
  }

  .Footer .credit {
    font-size: 12px;
  }
}